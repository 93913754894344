// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-east-2",
    aws_dynamodb_all_tables_region: "us-east-2",
    aws_dynamodb_table_schemas: [
        {
            tableName: "TicketManager",
            region: "us-east-2"
        }
    ],

    aws_user_files_s3_bucket: "ticket-manager-images",
    aws_user_files_s3_bucket_region: "us-east-2",
    aws_cognito_identity_pool_id: "us-east-2:173be41b-1de1-468b-ad0d-d488470cfbaa",
    aws_cognito_region: "us-east-2",
    aws_user_pools_id: "us-east-2_eaU6bxrus",
    aws_user_pools_web_client_id: "13jeflbs9mg7qb5gglm1je6ngi",
    oauth: {},
    aws_cloud_logic_custom: [
        {
            name: "ticketmanager7api",
            endpoint: "https://bse5fmvxoi.execute-api.us-east-2.amazonaws.com/",
            region: "us-east-2"
        }
    ],
};


export default awsmobile;
