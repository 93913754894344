import React from "react";
import "./App.css";
import Amplify, { API } from "aws-amplify";
import awsconfig from "./aws-exports";
import { withAuthenticator } from "aws-amplify-react"; // or 'aws-amplify-react-native';
import { AmplifyS3Image } from "@aws-amplify/ui-react";
// import { S3Image } from "aws-amplify-react/dist/Storage";
// import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

Amplify.configure(awsconfig);

class DisplayTicket extends React.Component {
  render() {
    // console.log("File:", this.props.file);

    if (!this.props.file && this.props.number) {
      return <h3>Ticket {this.props.number} Not Found</h3>;
    } else {
      return <AmplifyS3Image path={this.props.file} />;
    }
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      image: "",
      current: "",
      count: "",
      numbers: "",
      missing: [],
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.get();
  }

  get = async () => {
    // console.log("calling api");
    this.setState({ loading: true });
    let token = (await Amplify.Auth.currentSession()).idToken.jwtToken
    const response = await API.get(
      "ticketmanager7api",
      "/ticket/" + this.state.value,
      {headers: {
          Authorization: token
      }}
    );
    this.setState({
      current: this.state.value,
      image: response.ticketImage,
      loading: false,
    });
    // console.log(
    //   "api call:",
    //   response.ticketImage,
    //   this.state.value,
    //   this.state.current,
    //   this.state.image,
    //   this.state.count,
    //   this.state.missing
    // );
  };

  componentDidMount() {
    this.getTicketCount();
  }

  async getTicketCount() {
    // console.log("calling get ticket count api");
    let token = (await Amplify.Auth.currentSession()).idToken.jwtToken
    API.get(
      "ticketmanager7api",
      "/stats",
      {headers: {
          Authorization: token
      }}
    )
      .then((response) =>
        this.setState({ count: response.ItemCount, missing: response.MissingNumbers })
      );
  }

  render() {
    return (
      <div className="App">
        <section className="appcontainer">
          <div>Ticket Count: {this.state.count}</div>
          <form id="my-form" onSubmit={this.handleSubmit}>
            <div>
              <label>Ticket Number:</label>
              <input
                type="number"
                id="ticketNumber"
                value={this.state.value}
                onChange={this.handleChange}
              />
            </div>
            {this.state.loading ? (
              <div className="spinner">
                <Spinner animation="border" variant="dark" />
              </div>
            ) : (
              <input className="btn" type="submit" value="Submit" />
            )}
          </form>
        </section>

        <DisplayTicket
          key={this.state.current}
          file={this.state.image}
          number={this.state.current}
        />

        <div>Tickets still out: {1000 - this.state.count}</div>
        <Container>
          {this.state.missing.map((ticket) => (
            <Col key={ticket}>{ticket}</Col>
          ))}
        </Container>
      </div>
    );
  }
}

export default withAuthenticator(App, true);
